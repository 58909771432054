import React, { useEffect } from "react";
import Header from "../Components/Home/Header";
import Breadcrumb from "../Components/common/Breadcrumb";
import Book from "../Components/booknow/Book";
import Footer from "./Footer";

function BookNow() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"assets/images/background/booknow.jpg"}
          title={"Booking"}
          description={
            "Don't wait secure your luxury chauffeur experience today. Booking with us is quick and hassle-free, ensuring a stress-free journey."
          }
        />
      </div>
      <Book />
      <Footer />
    </div>
  );
}

export default BookNow;
