import React from "react";

function Luxury() {
  return (
    <div className="divvvvvv">
      <div className="container">
        <div className="row g-5">
          <div className="col-md-6">
            <div className="mt-5">
              <img
                src="assets/images/background/15.jpg"
                alt=""
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <h1 className="text-luxury d-none d-lg-block blue">
              {" "}
               BEST LUXURY<br></br> CHAUFFEURS <br></br> SERVICE
              <br></br> MELBOURNE
            </h1>
            <h1 className="display-1 d-block d-lg-none blue">
              {" "}
              LUXURY BEST <br></br> CHAUFFEURS <br></br> SERVICE
              <br></br> MELBOURNE
            </h1>
            <div className="dec-luxury text-black">
              Welcome to Reliable Chauffeur Service's Luxury Chauffeurs Service in
              Melbourne, where luxury knows no bounds. Since our establishment
              in 2015, we have proudly served discerning travelers with a
              commitment to excellence. We prioritize private and secure
              transportation, recognizing the significance of every journey. Our
              unwavering dedication to your comfort means that no element of
              luxury is ever compromised. Whether you're on your way to a
              crucial business meeting, navigating airport transfers, or simply
              eager to explore the city in style, our chauffeurs are your
              trusted guides. Discover life on your terms with us.
            </div>
            <div className="col-lg-4 mt-4">
              <div className="post-text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Luxury;
