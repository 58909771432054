import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";
import RequestQuote from "../../Components/quote/RequestQuote";

function WinaryTour() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/tourbigservices.jpg"}
          title={"Winary Tour"}
          description={""}
        />
      </div>
      <div className="container">
        <h2 className="mt-3"> Reliable Chauffeur Service - Unforgettable Tours</h2>
        <p className="mt-3">
          At Reliable Chauffeur Service, we are dedicated to making your time in
          Melbourne and around Australia truly unforgettable. Our
          custom-tailored tours offer you a chance to explore the beauty and
          culture of this diverse land at your own pace. Let us help you create
          a personalized tour that perfectly suits your needs, accompanied by
          our premium chauffeur service.
        </p>
        <h2 className="mt-3">Why Choose Reliable Chauffeur Service for Your Tours?</h2>
        <p className="mt-3">
          <strong>Customized Experiences:</strong> <br></br>We understand that
          every traveler is unique. Our consultants work closely with you to
          create an individualized tour that matches your interests and desires.
          Whether you're into wine tours, city exploration, or sightseeing,
          we've got you covered.<br></br>
          <strong>Professional Chauffeurs:</strong>
          <br></br>Your journey will be guided by our professional chauffeurs
          who possess an in-depth knowledge of the local area. They'll share
          insights, local stories, and historical facts, making your tour more
          enriching.
          <br></br>
          <strong>Safety and Comfort:</strong>
          <br></br>
          Your safety is our top priority. Our chauffeurs are highly trained,
          and our vehicles are equipped with advanced safety features, ensuring
          you travel with peace of mind.<br></br>
        </p>
      </div>
      <RequestQuote/>
      <Footer />
    </div>
  );
}

export default WinaryTour;
