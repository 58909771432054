import React from "react";

function OurGoal() {
  return (
    <div className="container ">
      <div className="row">
        <h1 className="text-center mb-4 blue">Our Goals</h1>
        <div className="col-md-4 text-center">
          <h5 style={{ color: "#032b4e" }}>Our Vision</h5>
          <p className="  text-black">
            At Reliable Chauffeur Service, our vision is powered by cutting-edge
            software that empowers us to monitor vehicles in real-time,
            enhancing operational efficiency and minimizing downtime. We have
            harnessed high-tech vehicle tracking systems, effectively reducing
            wait times and elevating customer satisfaction to new heights.
          </p>
        </div>
        <div className="col-md-4 text-center">
          <h5 style={{ color: "#032b4e" }}>Our Mission</h5>
          <p className="  text-black ">
            At Reliable Chauffeur Service, our missionis firmly rooted in delivering an
            exclusive, discreet, secure, and dependable luxury transportation
            service. We were founded with a straightforward aspiration: to be
            the industry's pinnacle. Our ultimate aim is to ensure that every
            one of our clients experiences a sense of special treatment and
            comfort as we expertly convey them from one location to another.
          </p>
        </div>
        <div className="col-md-4 text-center">
          <h5 style={{ color: "#032b4e" }}>Our Approach</h5>
          <p className="  text-black ">
            At Reliable Chauffeur Service, our approach is centered on delivering the
            utmost in VIP service quality. Our unwavering commitment to
            maintaining and servicing our fleet to the most exacting standards
            ensures that each vehicle is presented in impeccable condition to
            our valued customers.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurGoal;
