import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home";
import Service from "./Pages/Service";
import AirportTransfersMelbourne from "./Pages/services/AirportTransfersMelbourne";
import BookNow from "./Pages/BookNow";
import CorporateChauffeur from "./Pages/services/CorporateChauffeur";
import About from "./Pages/About";
import QuoteNow from "./Pages/QuoteNow";
import PrivacyPolicy from "./Pages/about/PrivacyPolicy";
import Terms from "./Pages/about/Terms";
import FAQ from "./Pages/about/FAQ";
import ServiceTerms from "./Pages/about/ServiceTerms";
import PointTransfer from "./Pages/services/PointTransfer";
import WeddingTransfer from "./Pages/services/WeddingTransfer";
import EventTransfer from "./Pages/services/EventTransfer";
import WinaryTour from "./Pages/services/WinaryTour";
import SmsTerms from "./Pages/about/SmsTerms";
function App() {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/booknow" element={<BookNow />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/quote" element={<QuoteNow />} />
        <Route
          exact
          path="/services/airport-transfers"
          element={<AirportTransfersMelbourne />}
        />
        <Route
          exact
          path="/services/corporate-chauffeur"
          element={<CorporateChauffeur />}
        />
        <Route
          exact
          path="/services/point-trasnfer"
          element={<PointTransfer />}
        />
        <Route
          exact
          path="/services/wedding-transfer"
          element={<WeddingTransfer />}
        />
        <Route exact path="/services/winery-tour" element={<WinaryTour />} />
        <Route
          exact
          path="/services/event-transfer"
          element={<EventTransfer />}
        />
        <Route exact path="/about/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/about/terms-and-conditions" element={<Terms />} />
        <Route exact path="/about/faqs" element={<FAQ />} />
        <Route
          exact
          path="/about/service-terms-and-conditions"
          element={<ServiceTerms />}
        />
        <Route
          exact
          path="//about/sms-terms-and-conditions"
          element={<SmsTerms />}
        />
      </Routes>
    </Router>
  );
}

export default App;
