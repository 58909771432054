import React, { useState } from "react";
import ServicesCard from "./ServicesCard";
import Carousel from "react-bootstrap/Carousel";

function ServicesCarousel() {
  // eslint-disable-next-line
  const [services, setServices] = useState([
    {
      image: "assets/images/news/airporttransfersservices-1.jpg",
      title: "Airport Transfer",
      description:
        "Experience the luxury of Reliable Chauffeur Service. so you can skip the taxi rank wait on your next journey.",
      route: "/services/airport-transfers",
    },
    {
      image: "assets/images/news/corporatetransfers-1.jpg",
      title: "Corporate Chauffeur",
      description:
        "Reliable Chauffeur Service values your time and offers efficient, premium transportation and making the most of your journey.",
      route: "/services/corporate-chauffeur",
    },
    {
      image: "assets/images/news/specialeventssmall1.jpg",
      title: "Event Transfer",
      description:
        "Arrive in luxury after your cruise. Reliable Chauffeur Service offers pick-ups at cruise ship with Chauffeured Cars.",
      route: "/services/event-transfer",
    },
    {
      image: "assets/images/news/sportsmall1.jpg",
      title: "Point To Point Transfer",
      description:
        "Elevate your sporting event experience with Reliable Chauffeur Service. Travel the luxuriously with Chauffeured Cars.",
      route: "/services/point-trasnfer",
    },
    {
      image: "assets/images/news/weddingssmall1 .jpg",
      title: "Wedding Transfer",
      description:
        "Elevate your event experience with Reliable Chauffeur Service. Enjoy a luxurious, with Signature Chauffeured Cars.",
      route: "/services/wedding-transfer",
    },
    {
      image: "assets/images/news/tourservices1.jpg",
      title: "Winery Tours",
      description:
        "Explore our diverse tour options at Reliable Chauffeur Service. We offer a range of vehicles for your preferences and needs.",
      route: "/services/winery-tour",
    },
  ]);


  let [a, setA] = useState(3)
  window.addEventListener("resize" , function(){
   if(window.innerWidth < 987){
    setA(1)
   } else{
    setA(3)
   }
    
  })
 
 
  const rows = [];
  for (let i = 0; i < services.length; i += 3) {
    rows.push(services.slice(i, i + a));
  }

  return (

    <>
      <h1 style={{color: '#28f1f4'}} className="text-center my-5 fw-bold">Our Services</h1>
      <div className="container">
        <Carousel>
          {rows.map((row, rowIndex) => (
            <Carousel.Item key={rowIndex}>
              <div className="row">
                {row.map((service, index) => (
                    <ServicesCard key={index} data={service} />
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      </>
  );
}

export default ServicesCarousel;