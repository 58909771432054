import React, { useEffect } from "react";
import Header from "../Components/Home/Header";
import Cards from "../Components/Home/Cards";
import Luxury from "../Components/Home/Luxury";
import Footer from "./Footer";
import WhyChoose from "../Components/Home/WhyChoose";
import Testimonials from "../Components/Home/Testimonials";
import CallNow from "../Components/Home/CallNow";
import Goal from "../Components/Home/Goal";
import { Link } from "react-router-dom";
import Slider from "../Components/Home/Slider";
import Atropos from "atropos/react";
import "atropos/css";
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper">
      <div
        id="carouselExampleCaptions"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
          ></li>
        </ol>
        <Header />

        <div className="carousel-inner">
          <div
            className="carousel-item active"
            style={{
              backgroundImage:
                'linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),URL("assets/images/slider/1.jpg")',
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="carousel-caption pb-5 mb-5 mx-auto">
              <Atropos className="d-none d-xl-block">
                <h5 className="display-1 h4-md mb-3 font-weight-bold text-white-50">
                  Premium Chauffeur Services in Australia
                </h5>
                <p className="h4 mb-5 py-2 text-white rounded-2">
                  Discover the Difference: Unparalleled Premium Transfers,
                  Tours, and Event Transportation.
                </p>

                <div className="btn-container">
                  <Link
                    to="/booknow"
                    style={{ backgroundColor: "white", color: "black" }}
                    className="btn btn-lg hov-btn btn-home  btn-main"
                  >
                    Book Now
                  </Link>
                  <Link to="/about" className="btn btn-home  btn-lg btn-main">
                    About us
                  </Link>

                  <Link
                    to="/quote"
                    style={{ backgroundColor: "white", color: "black" }}
                    className="btn hov-btn btn-home btn-lg btn-main"
                  >
                    Quote Now
                  </Link>
                </div>
              </Atropos>
              <div className="d-block d-xl-none">
                <h5 className="display-1 h4-md mb-3 font-weight-bold text-white-50">
                  Premium Chauffeur Services in Australia
                </h5>
                <p className="h4 mb-5 py-2 text-white rounded-2">
                  Discover the Difference: Unparalleled Premium Transfers,
                  Tours, and Event Transportation.
                </p>

                <div className="btn-container">
                  <Link
                    to="/booknow"
                    style={{ backgroundColor: "white", color: "black" }}
                    className="btn btn-lg hov-btn btn-home  btn-main"
                  >
                    Book Now
                  </Link>
                  <Link to="/about" className="btn btn-home  btn-lg btn-main">
                    About us
                  </Link>

                  <Link
                    to="/quote"
                    style={{ backgroundColor: "white", color: "black" }}
                    className="btn hov-btn btn-home btn-lg btn-main"
                  >
                    Quote Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="carousel-item active"
            style={{
              backgroundImage:
                'linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),URL("assets/images/slider/3.jpg")',
              height: "100vh",
              backgroundSize: "cover",
            }}
          >
            <div className="carousel-caption pb-5 mb-5 mx-auto">
              <Atropos className="d-none d-xl-block">
                <h5 className="display-1 h4-md mb-3 font-weight-bold text-white-50">
                  Australia's Premium Chauffeur Services
                </h5>
                <p className="h4 mb-5 py-2 text-white rounded-2">
                Experience the comfort with Exceptional Premium Airport Transfers, Tours, and Event Transport.
                </p>

                <div className="btn-container">
                  <Link to="/booknow" className="btn btn-home  btn-lg btn-main">
                    Book Now
                  </Link>

                  <Link
                    to="/about"
                    style={{ backgroundColor: "white", color: "black" }}
                    className="btn hov-btn btn-home  btn-lg btn-main"
                  >
                    About us
                  </Link>
                  <Link to="/quote" className="btn btn-home  btn-lg btn-main">
                    Quote Now
                  </Link>
                </div>
              </Atropos>
              <div className="d-block d-xl-none">
                <h5 className="display-1 h4-md mb-3 font-weight-bold text-white-50">
                  Australia's Premium Chauffeur Services
                </h5>
                <p className="h4 mb-5 py-2 text-white rounded-2">
                Experience the comfort with Exceptional Premium Airport Transfers, Tours, and Event Transport.
                </p>

                <div className="btn-container">
                  <Link to="/booknow" className="btn btn-home  btn-lg btn-main">
                    Book Now
                  </Link>

                  <Link
                    to="/about"
                    style={{ backgroundColor: "white", color: "black" }}
                    className="btn hov-btn btn-home  btn-lg btn-main"
                  >
                    About us
                  </Link>
                  <Link to="/quote" className="btn btn-home  btn-lg btn-main">
                    Quote Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cards />
      <Luxury />
      <Slider />
      <WhyChoose />
      <Testimonials />
      <CallNow />
      <Goal />
      <Footer />
    </div>
  );
}

export default Home;
