import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div id="topbar" className="topbar-dark text-light">
      <div className="container">
        <div className="topbar-left xs-hide">
          <div className="topbar-widget">
            <div className="topbar-widget">
              <Link to="/">
                <i className="fa fa-phone"></i>
                <a href="tel:+61480297751" className="text-white">
                  +61 48 02 97 751
                </a>
              </Link>
            </div>
            <div className="topbar-widget">
              <Link to="/">
                <i className="fa fa-envelope"></i>
                <a
                  href="mailto:bookings@reliabletaxichauffeurservice.com.au"
                  className="text-white"
                >
                  bookings@reliablechauffeurservice.com.au
                </a>
              </Link>
            </div>
            <div className="topbar-widget">
              <Link to="/">
                <i className="fa fa-clock-o"></i>Mon - Fri 08.00 - 18.00
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
