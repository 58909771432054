import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";
import RequestQuote from "../../Components/quote/RequestQuote";

function PointTransfer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/news/sportsmall1.jpg"}
          title={"Point To Point Transfer"}
          description={""}
        />
      </div>
      <div className="container">
        <h2 className="mt-5">Reliable Chauffeur Service - Point to Point Transfer </h2>
        <p className="mt-3">
          Welcome to Reliable Chauffeur Service's dedicated page for our Point to Point
          Transfer services. Whether you're a local resident or a traveler, our
          point to point transfers are designed to offer you a seamless,
          comfortable, and stress-free transportation experience. We understand
          the importance of getting to your destination safely and on time, and
          our professional chauffeurs are here to make that happen.
        </p>
        <h2 className="mt-2">
          {" "}
          Why Choose Reliable Chauffeur Service for Point to Point Transfers?
        </h2>
        <p className="mt-3">
          <strong>Reliability:</strong> <br></br>We take pride in our
          punctuality. When you book a point to point transfer with us, you can
          rely on us to be there exactly when you need us. No more worrying
          about missing your appointments or flights.<br></br>
          <strong>Professional Chauffeurs:</strong>
          <br></br>Our chauffeurs are highly trained, experienced, and
          courteous. They are committed to providing you with a top-notch
          service. Sit back, relax, and enjoy the ride while we take care of the
          rest.
          <br></br>
          <strong>Comfort and Style:</strong>
          <br></br>
          We maintain a fleet of luxurious vehicles to ensure your journey is as
          comfortable and stylish as possible. Our cars are designed to meet the
          highest standards of luxury and safety.<br></br>
          <strong>Competitive Rates:</strong>
          <br></br>We offer competitive pricing for our point to point transfer
          services. Enjoy the benefits of premium chauffeur-driven
          transportation without breaking the bank.<br></br>
          <strong>Safety First:</strong>
          <br></br> Your safety is our top priority. All our vehicles are
          equipped with safety features to ensure a secure journey. Rest easy
          knowing you're in good hands.<br></br>{" "}
          </p>
      </div>
      <RequestQuote/>
      <Footer />
    </div>
  );
}

export default PointTransfer;
