import React from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Faqs from "../../Components/about/Faqs";
import Footer from "../Footer";

function FAQ() {
  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/faq.jpg"}
          title={"FAQs"}
          description={""}
        />
      </div>
      <Faqs/>
      <Footer/>
    </div>
  );
}
export default FAQ;
