import React from "react";
import { Link } from "react-router-dom";

function ServicesCard(props) {
  return (
    <div className="col-lg-4 mb20">
      <div className="bloglist s2 item">
        <div className="post-content">
          <div className="post-image">
            <img alt="" src={props.data.image} className="lazy" />
          </div>
          <div
            style={{ color: "#1ecb15" }}
            className="post-text shadow p-3 mb-5 bg-body rounded-5"
          >
            <h4>
              <Link to="/">
                {props.data.title}
                <span></span>
              </Link>
            </h4>
            <p className="text-black">{props.data.description}</p>
              <Link to={props.data.route} className="btn-button btn-lg btn-main">
                Read Now
              </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCard;
