import React from "react";
import { Link } from "react-router-dom";
import { UilUser, UilSuitcase, UilShoppingBag } from "@iconscout/react-unicons";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
function Book() {
  function handleclick() {
    Swal.fire("+61 48 02 97 751");
  }
  return (
    <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="spacer-single sm-hide"></div>
            <div className="p-4 rounded-3 shadow-soft" data-bgcolor="#ffffff">
              <div id="step-1" className="row">
                <div className="col-lg-12 mb30">
                  <h5 style={{ color: "#13999b" }}>
                    What is your vehicle type?
                  </h5>

                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="de-item-list mb30">
                          <div className="d-img">
                            <img
                              src="/assets/images/cars-alt/premium.png"
                              className="img-fluid"
                              alt="Premium Sedan"
                            />
                          </div>
                          <div className="d-info blue">
                            <div className="d-text">
                              <h4 className="blue">Premium Sedan</h4>
                              <div className="d-atr-group text-black">
                                <UilUser></UilUser>
                                <span> 1-3 Passengers</span>
                                <br></br>
                                <UilSuitcase></UilSuitcase>
                                <span> 1-2 Suit Cases</span>
                                <br></br>
                                <UilShoppingBag></UilShoppingBag>
                                <span> 1-2 Small Bags</span>
                                <br></br>
                              </div>
                            </div>
                          </div>
                          <div className="d-price text-black fw-semibold mt-3">
                            <Link
                              onClick={handleclick}
                              className={`btn-home btn-lg btn-main pull-left`}
                            >
                              Book Now
                            </Link>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="de-item-list mb30">
                          <div className="d-img">
                            <img
                              src="/assets/images/cars-alt/executive.png"
                              className="img-fluid"
                              alt="Executive Sedan"
                            />
                          </div>
                          <div className="d-info blue">
                            <div className="d-text">
                              <h4 className="blue">Executive Sedan</h4>
                              <div className="d-atr-group text-black">
                                <UilUser></UilUser>
                                <span className=""> 1-3 Passengers</span>
                                <br></br>
                                <UilSuitcase></UilSuitcase>
                                <span> 1-2 Suit Cases</span>
                                <br></br>
                                <UilShoppingBag></UilShoppingBag>
                                <span> 1-3 Small Bags</span>
                                <br></br>
                              </div>
                            </div>
                          </div>
                          <div className="d-price text-black fw-semibold mt-3 ">
                            <Link
                              onClick={handleclick}
                              className={`btn-home btn-lg btn-main pull-left`}
                            >
                              Book Now
                            </Link>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="de-item-list mb30">
                          <div className="d-img">
                            <img
                              src="/assets/images/cars-alt/suv1.png"
                              className="img-fluid"
                              alt="SUV"
                            />
                          </div>
                          <div className="d-info blue">
                            <div className="d-text">
                              <h4 className="blue">SUV</h4>
                              <div className="d-atr-group text-black">
                                <UilUser></UilUser>
                                <span> 1-4 Passengers</span>
                                <br></br>
                                <UilSuitcase></UilSuitcase>
                                <span> 1-3 Suit Cases</span>
                                <br></br>
                                <UilShoppingBag></UilShoppingBag>
                                <span> 1-4 Small Bags</span>
                                <br></br>
                              </div>
                            </div>
                          </div>
                          <div className="d-price text-black fw-semibold mt-3">
                            <Link
                              onClick={handleclick}
                              className={`btn-home btn-lg btn-main pull-left`}
                            >
                              Book Now
                            </Link>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="de-item-list mb30">
                          <div className="d-img">
                            <img
                              src="/assets/images/cars-alt/van1.png"
                              className="img-fluid"
                              alt="VAN"
                            />
                          </div>
                          <div className="d-info blue">
                            <div className="d-text">
                              <h4 className="blue">VAN</h4>
                              <div className="d-atr-group text-black">
                                <UilUser></UilUser>
                                <span> 1-7 Passengers</span>
                                <br></br>
                                <UilSuitcase></UilSuitcase>
                                <span> 1-4 Suit Cases</span>
                                <br></br>
                                <UilShoppingBag></UilShoppingBag>
                                <span> 1-5 Small Bags</span>
                                <br></br>
                              </div>
                            </div>
                          </div>
                          <div className="d-price text-black fw-semibold mt-3">
                            <Link
                              onClick={handleclick}
                              className={`btn-home btn-lg btn-main pull-left`}
                            >
                              Book Now
                            </Link>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="spacer-single"></div>

        <div className="row">
          <div className="col-md-3 ">
            <div className="feature-box style-4 text-center">
              <Link to="/">
                <i className="bg-color text-light i-boxed rounded fa fa-car"></i>
              </Link>
              <div className="text text-black">
                <Link to="/">
                  <h4 style={{ color: "#13999b" }}>Choose a vehicle</h4>
                </Link>
                Unlock unparalleled adventures and memorable journeys with our
                vast fleet of vehicles tailored to suit every need, taste, and
                destination.
              </div>
            </div>
          </div>

          <div className="col-md-3   ">
            <div className="feature-box style-4 text-center">
              <Link to="/">
                <i className="bg-color text-light i-boxed rounded fa fa-calendar"></i>
              </Link>
              <div className="text text-black">
                <Link to="/">
                  <h4 style={{ color: "#13999b" }}>Pick location &amp; date</h4>
                </Link>
                Pick your ideal location and date, and let us take you on a
                journey filled with convenience, flexibility, and unforgettable
                experiences.
              </div>
            </div>
          </div>

          <div className="col-md-3   ">
            <div className="feature-box style-4 text-center">
              <Link to="/">
                <i className="bg-color text-light i-boxed rounded fa fa-pencil-square-o"></i>
              </Link>
              <div className="text text-black">
                <Link to="/">
                  <h4 style={{ color: "#13999b" }}>Make a booking</h4>
                </Link>
                Secure your reservation with ease, unlocking a world of
                possibilities and embarking on your next adventure with
                confidence.
              </div>
            </div>
          </div>

          <div className="col-md-3  ">
            <div className="feature-box style-4 text-center">
              <Link to="/">
                <i className="bg-color text-light i-boxed rounded fa fa-smile-o"></i>
              </Link>
              <div className="text text-black">
                <Link to="/">
                  <h4 style={{ color: "#13999b" }}>Sit back & relax</h4>
                </Link>
                Hassle-free convenience as we take care of every detail,
                allowing you to unwind and embrace a journey filled comfort.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Book;
