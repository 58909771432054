import React, { useEffect } from "react";
import Footer from "./Footer";
import Breadcrumb from "../Components/common/Breadcrumb";
import Header from "../Components/Home/Header";
import Customers from "../Components/about/Customers";
import Card from "../Components/about/Card";
import OurGoal from "../Components/about/OurGoal";
import CompanyRoadmap from "../Components/about/CompanyRoadmap";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper ">
      <Header />
      <div className="no-bottom no-top " id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"assets/images/background/about.jpg"}
          title={"About Us"}
          description={
            "we're dedicated to provide unparalleled luxury transportation services, combining style, comfort, and professionalism to make every journey extraordinary."
          }
        />
      </div>
      <Customers />
      <Card />
      <div className="bg-white">
        <OurGoal />
        <CompanyRoadmap />
      </div>
      <Footer />
    </div>
  );
}

export default About;
