import React from "react";

function Breadcrumb(props) {

  const url = 'url("' + props.image + '")';

  return (
    <section
      id="subheader"
      style={{
        background: `linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),${url}`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className="jarallax text-light "
    >
      <div className="center-y relative text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{props.title}</h1>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <p className="lead">
                {props.description}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;
