import React from "react";
import Nav from "./TopNav";
import Navigation from "./Navigation";

function Header() {
  return (
    <header className="bg-transparent nav-up has-topbar">
      <Nav />
      <Navigation/>
    </header>
  );
}

export default Header;
