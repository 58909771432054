import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";
import RequestQuote from "../../Components/quote/RequestQuote";

function WeddingTransfer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/news/weddingbanner1.jpg"}
          title={"Wedding Transfer"}
          description={""}
        />
      </div>
      <div className="container">
        <h2 className="mt-5">Reliable Chauffeur Service - Wedding Transfers </h2>
        <p className="mt-3">
          Welcome to Reliable Chauffeur Service's dedicated page for our Wedding
          Transfer services. Your wedding day is a momentous occasion, and we
          are here to ensure your transportation is as special as the rest of
          your day. With our premium wedding transfer services, you can focus on
          the joy of the moment while we take care of your travel needs.
        </p>
        <h2 className="mt-2">
          {" "}
          Why Choose Reliable Chauffeur Service for Wedding Transfers?
        </h2>
        <p className="mt-3">
          <strong>Luxury and Elegance:</strong> <br></br>Your wedding day calls
          for the utmost luxury and elegance. We offer a fleet of high-end
          vehicles that are meticulously maintained to complement your special
          day. Your journey will be as exceptional as the event itself.<br></br>
          <strong>Professional Chauffeurs: </strong>
          <br></br>Our chauffeurs are experienced, impeccably dressed, and
          well-trained in providing exceptional service. They understand the
          significance of your wedding day and will go the extra mile to make it
          perfect.
          <br></br>
          <strong>Punctuality:</strong>
          <br></br>
          We are dedicated to being punctual and reliable. You can count on us
          to transport you and your wedding party to the venue on time, without
          any worries about delays.<br></br>
          <strong>Customized Services:</strong>
          <br></br>Your wedding is unique, and we understand that. We offer
          tailored wedding transfer services to accommodate your specific
          requests and preferences. Your vision for your wedding transportation
          becomes a reality with Reliable Chauffeur Service.<br></br>
          <strong>Comfort and Relaxation:</strong>
          <br></br> Your wedding day is bound to be filled with excitement. Our
          chauffeurs will ensure that you and your guests travel in comfort,
          helping you unwind and enjoy the journey.<br></br>{" "}
        </p>
        <h2 className="mt-2"> Our Wedding Transfer Services</h2>
        <p className="mt-3">
          <strong>Bride and Groom: </strong> <br></br>Our luxurious vehicles are
          perfect for the bride and groom, ensuring they arrive at the venue in
          style and comfort.<br></br>
          <strong>Wedding Party: </strong>
          <br></br>We can accommodate your entire wedding party. From
          bridesmaids to groomsmen, we have vehicles that will cater to the
          entire entourage
          <br></br>
          <strong>Guest Transportation: </strong>
          <br></br>
          If you're planning to transport your guests from one location to
          another, we have the vehicles and logistics to make it seamless.
          <br></br>
          <strong>Customized Themes:</strong>
          <br></br>Do you have a particular theme for your wedding? Let us know,
          and we can decorate the vehicle to match your theme.<br></br>
          <strong>Multiple Stops: </strong>
          <br></br> We understand that weddings often involve multiple stops,
          such as from home to the ceremony, then to the reception. Our service
          can be tailored to fit your unique itinerary.<br></br>{" "}
        </p>
      </div>
      <RequestQuote/>
      <Footer />
    </div>
  );
}

export default WeddingTransfer;
