import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <>
      <div className="container d-none d-xl-block">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex sm-pt10">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  <div id="logo">
                    <Link to="/">
                      <img
                        style={{ width: "200px" }}
                        className="logo-1"
                        src="/assets/images/logo-light.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="de-flex-col header-col-mid">
                <ul id="mainmenu">
                  <li>
                    <Link className="menu-item" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/about">
                      About
                    </Link>
                    <ul>
                      <li>
                        <Link className="menu-item " to="/about/faqs">
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/about/service-terms-and-conditions"
                        >
                          Service Terms and Conditions
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/about/terms-and-conditions"
                        >
                          Terms and Conditions
                        </Link>
                      </li>
                      <li>
                        <Link className="menu-item" to="/about/privacy-policy">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/about/sms-terms-and-conditions"
                        >
                          SMS Terms and Conditions
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link className="menu-item" to="/service">
                      Services
                    </Link>
                    <ul>
                      <li>
                        <Link
                          className="menu-item"
                          to="/services/airport-transfers"
                        >
                          Airport Transfers
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/services/corporate-chauffeur"
                        >
                          Corporate Chauffeur
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/services/event-transfer"
                        >
                          Event Transfer
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/services/point-trasnfer"
                        >
                          Point To Point Transfer
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="menu-item"
                          to="/services/wedding-transfer"
                        >
                          Wedding Transfer
                        </Link>
                      </li>
                      <li>
                        <Link className="menu-item" to="/services/winery-tour">
                          Winery Tour
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link className="menu-item" to="/quote">
                      Quote Now
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/booknow">
                      Book Now
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="de-flex-col header-col-mid"></div>
            </div>
          </div>
        </div>
        <div className="menu-container">
          <span className="menu" id="menu-btn"></span>
        </div>
      </div>

      <nav
        style={{ zIndex: 9999999999 }}
        className="navbar navbar-expand-lg d-block d-xl-none"
      >
        <div
          style={{ background: "rgba(0, 0, 0, 0.7)" }}
          className="container-fluid"
        >
          <Link className="m-2" to="/">
            <img
              style={{ width: "200px" }}
              src="/assets/images/logo-light.png"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img width="30" src="/assets/images/toggle.png" alt="toggle"></img>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav nav-flex me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown no-arrow border-bottom text-center w-100 pe-4">
                <Link className="menu-item drop  asd" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown border-bottom text-center w-100 pe-2">
                <Link
                  className="menu-item drop "
                  to="/about"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  About
                </Link>
                <ul className="dropdown-menu text-center">
                  <li>
                    <Link className="menu-item" to="/about/faqs">
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="menu-item"
                      to="/about/service-terms-and-conditions"
                    >
                      Service Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="menu-item"
                      to="/about/terms-and-conditions"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/about/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="menu-item"
                      to="/about/sms-terms-and-conditions"
                    >
                      SMS Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown border-bottom text-center w-100">
                <Link
                  className="menu-item drop "
                  to="/service"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  Services
                </Link>
                <ul className="dropdown-menu text-center">
                  <li>
                    <Link
                      className="menu-item"
                      to="/services/airport-transfers"
                    >
                      Airport Transfers
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="menu-item"
                      to="/services/corporate-chauffeur"
                    >
                      Corporate Chauffeur
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/services/point-trasnfer">
                      Point To Point Transfer
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/services/wedding-transfer">
                      Wedding Transfer
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/services/winery-tour">
                      Winery Tour
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-item" to="/services/event-transfer">
                      Event Transfer
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown no-arrow border-bottom text-center w-100">
                <Link className="menu-item drop  " to="/quote">
                  Quote Now
                </Link>
              </li>
              <li className="nav-item dropdown no-arrow border-bottom text-center w-100">
                <Link className="menu-item drop " to="/booknow">
                  Book Now
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navigation;
