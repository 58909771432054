import React from "react";

function Testimonials() {
  return (
    <div className="divvvv">
      <div className="container ">
        <h1 className="blue choose-sig text-center mb-5">Testimonials</h1>
        <div className="row">
          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote blue">
                  <i className="fa fa-quote-right"></i>
                </div>
                <h4>Excellent Service! Reliable Chauffeur Service!</h4>
                <blockquote>
                  For all my business trips, I've exclusively relied on
                  Reliable Chauffeur Service, and I've yet to encounter a subpar
                  experience. Whether it's the reservations team or the
                  chauffeurs themselves, their friendliness and professionalism
                  shine through. The vehicles are consistently pristine and
                  consistently punctual, a crucial aspect when you're on the
                  move for business or simply seeking efficient, safe, and
                  stylish transportation from point A to B. In summary, this
                  company is nothing short of exceptional!
                  <span className="by">Stepanie Hutchkiss</span>
                </blockquote>
              </div>
              <img
                src="/assets/images/testimonial/1.jpg"
                className="img-fluid mb-3"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote blue">
                  <i className="fa fa-quote-right"></i>
                </div>
                <h4>Excellent Service! Reliable Chauffeur Service!</h4>
                <blockquote>
                  Booking with our dedicated wedding team was a breeze. Our
                  highly responsive staff assisted us in crafting a meticulous
                  itinerary for the big day. What's more, all the vehicles
                  arrived well ahead of their scheduled time, and each one was
                  impeccably maintained.
                  <span className="by">Jovan Reels</span>
                </blockquote>
              </div>
              <img
                src="/assets/images/testimonial/2.jpg"
                className="img-fluid mb-3"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote blue">
                  <i className="fa fa-quote-right"></i>
                </div>
                <h4>Excellent Service! Reliable Chauffeur Service!</h4>
                <blockquote>
                  Hasan and his team consistently exhibit the utmost
                  professionalism and reliability. The vehicles, without
                  exception, are in outstanding condition. My loyalty to their
                  service spans over five years, and not once have they failed
                  to meet my expectations.
                  <span className="by">Kanesha Keyton</span>
                </blockquote>
              </div>
              <img
                src="/assets/images/testimonial/3.jpg"
                className="img-fluid mb-3"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
