import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/privacybg.jpg"}
          title={"Privacy Policy"}
          description={""}
        />
      </div>
      <div className="container">
        <p className="mt-5">
          <h2 className="mt-4">PRIVACY POLICY</h2>
        </p>
        <p className="mt-3">
          In our commitment to safeguard your privacy, Reliable Chauffeur Service has
          formulated this policy, unveiling our practices regarding the
          information we collect from you, along with its collection, storage,
          and utilization. We adhere to the Privacy Act 1988 (Cth), the
          Australian Privacy Principles (APPS), the Notifiable Data Breaches
          Scheme, and the European Union's General Data Protection Regulation.
          These regulations outline a set of principles aimed at preserving
          individual privacy. If you do not agree with the terms and conditions
          of this privacy policy, please refrain from using our website or
          services.
        </p>
        <p className="mt-3">
          <strong>1. What information is collected</strong> <br></br>At
          Reliable Chauffeur Service, we gather Personal Information provided by you
          through our website and services. You have the option to explore the
          informative sections of our website without disclosing any Personal
          Information. However, when you request information or materials from
          us, we may request your contact details, including your name, email
          address, company name, postal address, and phone number(s).
          Furthermore, we may collect personal information when you send us
          emails or submit other forms of information directly to us. For making
          reservations via our website, we will require you to complete a
          registration form. This form will ask for specific Personal
          Information about the website user and the traveler who will be
          utilizing our transportation services (referred to as "Client" if
          different from the User). This information will be compiled into a
          profile to facilitate services for Clients and/or Users. It may
          encompass details such as the User's name, address, email address,
          company name, home/mobile phone number, and for the Client, their
          name, email address, home/mobile phone number, company name, work
          address, home address, billing address, pick-up and drop-off details,
          credit card information, corporate account information,
          service-related preferences, and travel itineraries. Additionally, we
          may utilize browser cookies to identify you during your use of our
          website. Please review our cookies policy on our website for more
          details.
        </p>
        <p className="mt-3">
          <strong>
            2. How We Use Your Information or Disclose It to Third Parties.
          </strong>{" "}
          <br></br>The Personal Information we collect through our website and
          services is primarily used for the administration and delivery of our
          services. In the course of providing our services, we may share
          Personal Information with third-party partners or vendors who assist
          in delivering or administering these services. However, they are
          exclusively authorized to utilize this information in the context of
          delivering or administering our services. We may also share Personal
          Information with subsidiaries, affiliates, licensees, and
          subcontracted service providers under the condition that they require
          this information to provide the services you have requested. Personal
          Information collected through our website and services may be used to
          furnish you with information or newsletters pertaining to our
          services, as well as to promote our services to your company (as
          specified in your profile) with your consent, if required by
          applicable law. Non-personal information, in an anonymized and
          aggregated form, serves various purposes, including the operation and
          enhancement of our website, services, service delivery, internal
          marketing research, and management reporting. We retain the right to
          employ and disclose this aggregated information to third parties for
          any purpose. This aggregated data will not identify any specific
          individual. Additionally, we use pixels or transparent GIF files to
          assist in online advertising management. These GIF files are provided
          by our advertising partners, allowing them to recognize a unique
          cookie in your web browser. This, in turn, helps us understand which
          advertisements lead users to our website. The cookie may be placed by
          us or another advertiser working with our partners. The information we
          collect and share is anonymous and not personally identifiable. It
          does not contain your name, postal address, telephone number, or email
          address.
        </p>
        <p className="mt-3">
          <strong>
            3. Controlling the Collection, Use and Onward Transfer of Your
            Information.{" "}
          </strong>{" "}
          <br></br>The Personal Information collected through our website and
          services is primarily used for administering and delivering our
          services. While delivering our services, we may share this Personal
          Information with third-party partners or vendors who aid in the
          delivery or administration of these services. However, they are
          strictly authorized to employ this information solely for the purpose
          of delivering or administering our services. We may also share
          Personal Information with subsidiaries, affiliates, licensees, and
          subcontracted service providers, but only to the extent necessary to
          provide the services you have requested. Personal Information
          collected via the website and services may also be utilized to provide
          you with information or newsletters concerning our services.
          Additionally, with your consent, if mandated by applicable law, we may
          promote our services to your company, as listed in your profile.
          Non-personal information, presented in an anonymized and aggregated
          manner, serves various purposes, such as the operation and enhancement
          of our website, services, service delivery, internal marketing
          research, and management reporting. We retain the right to use and
          disclose this aggregated information to third parties for any purpose.
          This aggregated data will not identify any specific individual. We
          also employ pixels or transparent GIF files to assist in the
          management of online advertising. These GIF files are supplied by our
          advertising management partners and enable them to recognize a unique
          cookie in your web browser. This, in turn, helps us understand which
          advertisements lead users to our website. The cookie may be placed by
          us or another advertiser working with our partners. The information we
          collect and share is anonymous and not personally identifiable. It
          does not contain your name, postal address, telephone number, or email
          address.
        </p>
        <p className="mt-3">
          <strong>4. Accessing your personal information</strong> <br></br>
          Reliable Chauffeur Service reserves the right to amend or modify this Privacy
          Policy as necessary. Any revisions to this policy will be promptly
          posted on our website. It is advisable to periodically check our
          website to stay updated on our current policies and practices. Any
          User or Client providing additional Personal Information after these
          changes are posted will be considered to have accepted the revised
          policy. This Privacy Policy is currently in effect. We trust that this
          Privacy Policy provides clarity on our policies and procedures
          concerning your Personal Information.
        </p>
      </div>
      <div className="spacer-single"></div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
