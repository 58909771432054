import React from "react";
import { Link } from "react-router-dom";
function CallNow() {
  return (
    <div id="section-call-to-action" className="bg-color-2  text-light divvv">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 offset-lg-2">
            <span className="subtitle text-white">
              Call us for further information
            </span>
            <h2 className="s2">
              Are you ready to get started on your next bookings?
            </h2>
          </div>
          <div className="col-lg-4 text-lg-center text-sm-center">
            <div className="phone-num-big">
              <i className="fa fa-phone"></i>
              <span className="pnb-text">Call Us Now</span>
              <a href="tel:+61480297751" className="pnb-num text-white">
                +61480297751{" "}
              </a>
            </div>
            <div className="btn-container">
              <Link
                to="/booknow"
                className="btn btn-lg hov-btn btn-home  btn-main"
              >
                Book Now
              </Link>
              <Link to="/quote" className="btn btn-home  btn-lg btn-main">
                Quote Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallNow;
