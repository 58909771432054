import React, { useEffect } from "react";
import Header from "../Components/Home/Header";
import Services from "../Components/service/Services";
import Breadcrumb from "../Components/common/Breadcrumb";
import Footer from "./Footer";

function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"assets/images/background/airporttransfers.jpg"}
          title={"Services"}
          description={
            "Experience exceptional chauffeur services in Australia. We offer a wide range of luxury transportation options to meet your every need."
          }
        />
      </div>
      <Services />
      <Footer />
    </div>
  );
}

export default Service;
