import React, { useState } from "react";
import ServicesCard from "./ServicesCard";

function Services() {
  // eslint-disable-next-line
  const [services, setServices] = useState([
    {
      image: "assets/images/news/airporttransfersservices-1.jpg",
      title: "Airport Transfer",
      description:
        "Experience the luxury of Reliable Chauffeur Service. so you can skip the taxi rank wait on your next journey.",
      route: "/services/airport-transfers",
    },
    {
      image: "assets/images/news/corporatetransfers-1.jpg",
      title: "Corporate Chauffeur",
      description:
        "Reliable Chauffeur Service values your time and offers efficient, premium transportation and making the most of your journey.",
      route: "/services/corporate-chauffeur",
    },
    {
      image: "assets/images/news/specialeventssmall1.jpg",
      title: "Event Transfer",
      description:
        "Arrive in luxury after your cruise. Reliable Chauffeur Service offers pick-ups at cruise ship with Chauffeured Cars.",
      route: "/services/event-transfer",
    },
    {
      image: "assets/images/news/sportsmall1.jpg",
      title: "Point To Point Transfer",
      description:
        "Elevate your sporting event experience with Reliable Chauffeur Service. Travel the luxuriously with Chauffeured Cars.",
      route: "/services/point-trasnfer",
    },
    {
      image: "/assets/images/news/weddingssmall1 .jpg",
      title: "Wedding Transfer",
      description:
        "Elevate your event experience with Reliable Chauffeur Service. Enjoy a luxurious, with Signature Chauffeured Cars.",
      route: "/services/wedding-transfer",
    },
    {
      image: "assets/images/news/tourservices1.jpg",
      title: "Winery Tours",
      description:
        "Explore our diverse tour options at Reliable Chauffeur Service. We offer a range of vehicles for your preferences and needs.",
      route: "/services/winery-tour",
    },
  ]);

  return (
    <section  >
      <div className="container">
        <div className="row">
          {services.map((service, index) => (
            <ServicesCard data={service} key={index} />
          ))}
          <div className="spacer-single"></div>
        </div>
      </div>
    </section>
  );
}

export default Services;
