import React from "react";

function Customers() {
  return (
    <section>
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6  ">
            <h2>
              We offer customers a wide range of{" "}
              <span className="blue">commercial cars</span> and{" "}
              <span className="blue">luxury cars</span> for any occasion.
            </h2>
          </div>
          <div className="col-lg-6   text-black" data--delay=".25s">
            At Reliable Chauffeur Service, we're dedicated to deliver
            outstanding car hire services to our esteemed clients. With our
            unwavering commitment to quality, convenience, and your
            satisfaction, we aim to transform every car hire experience into a
            seamless and enjoyable journey. We recognize that each customer
            possesses unique needs and preferences when it comes to hiring a
            vehicle. This is why we maintain a diverse and well-maintained fleet
            of vehicles to cater to a wide range of requirements. Whether you're
            a solo traveler in need of a compact car or a family looking for a
            spacious SUV, we offer a plethora of options to suit your needs.
            Choose Reliable Chauffeur Service for your next travel and discover
            the perfect vehicle to complement your journey. We're here to make
            your transportation needs as smooth and comfortable as possible
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customers;
