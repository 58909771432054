import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../common/Loader";
import { usePlacesWidget } from "react-google-autocomplete";
import moment from "moment";

function RequestQuote() {
  const API_KEY = "AIzaSyBFF3_W3mLEgvTKxx3PxFx_Wk-OFiBCJJc";

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    datetime: "",
    pickuplocation: "",
    dropofflocation: "",
    cartype: "",
    passengers: "",
    luggage: "",
    babyseat: "",
    boosterseat: "",
    flightnumber: "",
    otherinformation: "",
  });

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const { ref: pickupRef } = usePlacesWidget({
    apiKey: API_KEY,
    onPlaceSelected: (place) =>
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        pickuplocation: place.formatted_address,
      })),
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: "au" },
    },
  });

  const { ref: dropoffRef } = usePlacesWidget({
    apiKey: API_KEY,
    onPlaceSelected: (place) =>
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        dropofflocation: place.formatted_address,
      })),
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: "au" },
    },
  });

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleReset = () => {
    setCredentials({
      name: "",
      email: "",
      phone: "",
      datetime: "",
      pickuplocation: "",
      dropofflocation: "",
      cartype: "",
      passengers: "",
      luggage: "",
      babyseat: "",
      boosterseat: "",
      flightnumber: "",
      otherinformation: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    var formdata = new FormData();
    formdata.append("name", credentials.name);
    formdata.append("email", credentials.email);
    formdata.append("phone", credentials.phone);
    formdata.append(
      "datetime",
      moment(credentials.datetime).format("MMMM Do YYYY, h:mm:ss a")
    );
    formdata.append("pickup", credentials.pickuplocation);
    formdata.append("dropoff", credentials.dropofflocation);
    formdata.append("cartype", credentials.cartype);
    formdata.append("passengers", credentials.passengers);
    formdata.append("luggage", credentials.luggage);
    formdata.append("babyseat", credentials.babyseat);
    formdata.append("boosterseat", credentials.boosterseat);
    formdata.append("flight", credentials.flightnumber);
    formdata.append("other", credentials.otherinformation);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://reliablechauffeurservice.com.au/backend/quote.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          toast("Message Sent Successfully");
          setCredentials({
            name: "",
            email: "",
            phone: "",
            datetime: "",
            pickuplocation: "",
            dropofflocation: "",
            cartype: "",
            passengers: "",
            luggage: "",
            babyseat: "",
            boosterseat: "",
            flightnumber: "",
            otherinformation: "",
          });
          setLoading(false);
        } else {
          toast(result.message);
          setCredentials({
            name: "",
            email: "",
            phone: "",
            datetime: "",
            pickuplocation: "",
            dropofflocation: "",
            cartype: "",
            passengers: "",
            luggage: "",
            babyseat: "",
            boosterseat: "",
            flightnumber: "",
            otherinformation: "",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  return (
    <section aria-label="section" className="pt-3">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h3
              className="fw-bold fs-2 text-center"
              style={{ color: "#28f1f4" }}
            >
              Request A Quote
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Full Name:</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={credentials.name}
                      onChange={onChange}
                      className="form-control form-control-md"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Email:</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={credentials.email}
                      onChange={onChange}
                      required
                      className="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Phone:</label>
                    <input
                      type="number"
                      name="phone"
                      id="typeNumber"
                      value={credentials.phone}
                      onChange={onChange}
                      required
                      className="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Date & Time:</label>
                    <input
                      type="datetime-local"
                      name="datetime"
                      id="name"
                      value={credentials.datetime}
                      onChange={onChange}
                      required
                      min={getCurrentDateTime()}
                      className="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Pick Up Location:</label>
                    <input
                      type="text"
                      ref={pickupRef}
                      name="pickuplocation"
                      autocomplete="off"
                      // value={credentials.pickuplocation}
                      // onChange={onChange}
                      required
                      className="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Drop Off Location:</label>
                    <input
                      type="text"
                      ref={dropoffRef}
                      name="dropofflocation"
                      // value={credentials.dropofflocation}
                      autocomplete="off"
                      // onChange={onChange}
                      required
                      className="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Car Type:</label>
                    <select
                      onChange={onChange}
                      name="cartype"
                      value={credentials.cartype}
                      className="form-select form-select-md"
                      required
                    >
                      <option selected>Please Select</option>
                      <option value="Sedan">Sedan</option>
                      <option value="Suv">Suv</option>
                      <option value="Van">Van</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Number of Passengers:</label>
                    <select
                      onChange={onChange}
                      name="passengers"
                      className="form-select form-select-md"
                      required
                      value={credentials.passengers}
                    >
                      <option selected>Please Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="12">11</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Luggage:</label>
                    <select
                      onChange={onChange}
                      name="luggage"
                      className="form-select form-select-md"
                      required
                      value={credentials.luggage}
                    >
                      <option selected>Please Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="12">11</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Baby Seat:</label>
                    <select
                      onChange={onChange}
                      name="babyseat"
                      value={credentials.babyseat}
                      className="form-select form-select-md"
                    >
                      <option selected>Please Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Booster Seat:</label>
                    <select
                      onChange={onChange}
                      name="boosterseat"
                      className="form-select form-select-md"
                      value={credentials.boosterseat}
                    >
                      <option selected>Please Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set mb-3">
                    <label>Flight Number(optional):</label>
                    <input
                      type="text"
                      name="flightnumber"
                      onChange={onChange}
                      value={credentials.flightnumber}
                      id="typeNumber"
                      className="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="field-set mb-3">
                    <label>Other Information:</label>
                    <textarea
                      name="otherinformation"
                      value={credentials.otherinformation}
                      onChange={onChange}
                      className="form-control form-control-md"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="btn-container text-start ">
                    <button type="submit" className="btn-home btn-lg btn-main">
                      {loading ? <Loader /> : "Submit"}
                    </button>
                    {loading ? (
                      ""
                    ) : (
                      <button
                        style={{ backgroundColor: "#031b4e" }}
                        onClick={handleReset}
                        type="reset"
                        className="btn-home  btn-lg btn-main"
                      >
                        Reset
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default RequestQuote;
