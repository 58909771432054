import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
function Footer() {
  function handleclick() {
    Swal.fire("+61 48 02 97 751");
  }
  return (
    <footer className="text-light bg-dark">
      <div className="container">
        <div className="row g-custom-x">
          <div className="col-md-3">
            <div className="widget">
              <h5>About Reliable Chauffeur Service</h5>
              <p>
                Where quality meets affordability. We understand the importance
                of a smooth and enjoyable journey without the burden of
                excessive costs. That's why we have meticulously crafted our
                offerings to provide you with top-notch vehicles at minimum
                expense.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="widget">
              <h5>Contact Info</h5>
              <address className="s1">
                <span>
                  <i className="blue fa fa-map-marker fa-lg"></i>16 Giselle Cct,
                  Greenvale VIC 3059
                </span>
                <span>
                  <i className="blue fa fa-phone fa-lg"></i>
                  <a href="tel:+61480297751" className="text-white">
                    +61 48 02 97 751
                  </a>
                </span>
                <span>
                  <i className="blue fa fa-envelope-o fa-lg"></i>
                  <Link to="mailto:bookings@reliabletaxichauffeurservice.com.au">
                    bookings@reliablechauffeurservice.com.au
                  </Link>
                </span>
              </address>
            </div>
          </div>

          <div className="col-md-2">
            <h5>Quick Links</h5>
            <div className="row">
              <div className="col-lg-6">
                <div className="widget">
                  <ul>
                    <li>
                      <Link to="/">About</Link>
                    </li>
                    <li>
                      <Link to="/">Blog</Link>
                    </li>
                    <li>
                      <Link to="/">Careers</Link>
                    </li>
                    <li>
                      <Link to="/">News</Link>
                    </li>
                    <li>
                      <Link to="/">Partners</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="widget">
              <h5>Social Network</h5>
              <div className="social-icons">
                <Link to="/">
                  <i className="fa fa-facebook fa-lg"></i>
                </Link>
                <Link to="/">
                  <i className="fa fa-twitter fa-lg"></i>
                </Link>
                <Link to="/">
                  <i className="fa fa-linkedin fa-lg"></i>
                </Link>
                <Link to="/">
                  <i className="fa fa-pinterest fa-lg"></i>
                </Link>
                <Link to="/">
                  <i className="fa fa-rss fa-lg"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-0 mb-0">
        <div className="row pb-0 mb-0">
          <div className="col-md-6 d-flex justify-content-center">
            <Link to="/">
              Copyright &copy; 2023 - Reliable Chauffeur Service - All Rights
              Reserved
            </Link>
          </div>
          <div className="col-md-6 mt-md-0 mt-3 d-flex justify-content-center">
            <img
              className=""
              src="../assets/images/icons/payment.png"
              alt="Payment Methods"
            />
          </div>
        </div>
      </div>
      <div id="purchase-now">
        <Link onClick={handleclick} rel="noreferrer">
          <img
            src="../assets/images/icons/call.png"
            height={"55px"}
            alt="whatsapp icon"
          />
        </Link>
        <br />
        <br />
        <br />
        <a href="https://wa.me/610480297751" rel="noreferrer" target="_blank">
          <img
            src="../assets/images/icons/whatsapp.png"
            height={"55px"}
            alt="whatsapp icon"
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
