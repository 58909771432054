import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/terms.jpg"}
          title={"Terms and Conditions"}
          description={""}
        />
      </div>
      <div className="container">
        <p className="mt-5">
          <h2 className="mt-4"> TERMS & CONDITIONS</h2>
        </p>
        <p className="mt-3">
          We, at Reliable Chauffeur Service, are deeply committed to safeguarding your
          privacy. In line with this commitment, we have formulated this policy,
          which unveils our privacy practices concerning the information we
          collect from you, as well as its collection, storage, and utilization.
          We diligently adhere to the Privacy Act 1988 (Cth), the Australian
          Privacy Principles (APPS), the Notifiable Data Breaches Scheme, and
          the General Data Protection Regulation of the European Union. These
          regulations lay down a set of principles aimed at preserving
          individual privacy. If you do not concur with the terms and conditions
          outlined in this privacy policy, we kindly request that you refrain
          from using our website or services.
        </p>
        <p className="mt-3">
          <strong>1. What information is collected</strong> <br></br>At
          Reliable Chauffeur Service, we collect Personal Information that you provide
          to us through our website or services. You are welcome to explore the
          informative sections of our website without the necessity of providing
          us with any Personal Information. However, should you seek information
          or materials from us, we may request your contact details, including
          your name, email address, company name, postal address, and phone
          number(s). Furthermore, personal information may be gathered when you
          send us emails or submit other forms of information directly to us. To
          make reservations via our website, you will be prompted to complete a
          registration form. This form will solicit specific Personal
          Information regarding the website user and the traveler who intends to
          utilize our transportation services (referred to as "Client" if
          different from the User). Certain elements of this information may be
          compiled into a profile to facilitate services for Clients and/or
          Users. This information could encompass, at a minimum, the following:
          for the User, name, address, email address, company name, home/mobile
          phone number, and for the Client, name, email address, home/mobile
          phone number, company name, work address, home address, billing
          address, pick-up and drop-off details, credit card information,
          corporate account information, specific service-related preferences,
          and travel itineraries. Additionally, we may employ a browser feature
          known as a "cookie" to recognize your presence while using our
          website. It's important to note that cookies are small pieces of
          information stored on your hard drive, not on our website. We may
          grant specific third parties permission to use cookies and similar
          technologies to collect information about your online activities
          across different websites and over time. For further information,
          please consult our cookies policy on our website.
        </p>
        <p className="mt-3">
          <strong>
            2. How We Use Your Information or Disclose It to Third Parties.
          </strong>{" "}
          <br></br>We use the Personal Information collected through our Web
          Site and services to administer and deliver our services. In
          connection with the delivery of our services, we may share the
          Personal Information with third-party partners or vendors who help
          deliver or administer the services, but they are only permitted to use
          the information in connection with the delivery or administration of
          our services. We may share Personal Information with Signature
          Chauffeurs subsidiaries, affiliates, licensees, and subcontracted
          service providers who need that information to provide the services
          that you have requested.<br></br>We may use the Personal Information
          collected through the Web Site and Services to provide you with
          information or newsletters related to our services, and to promote our
          services to your company as listed in your profile, where you have
          provided your consent if required under the applicable law.<br></br>We
          use non-personal information on an anonymized, aggregated basis for a
          variety of purposes, including operating and enhancing our Web Site,
          Services and our service delivery, internal marketing research, and
          management reporting. We reserve the right to use and disclose this
          aggregated information to third parties for any purpose. Aggregate
          information will not identify any one specific individual. We also use
          pixels, or transparent GIF files, to help manage online advertising.
          These GIF files are provided by our advertisement management partners.
          These files enable our partners to recognize a unique cookie on your
          Web browser, which in turn enables us to learn which advertisements
          bring users to our website. The cookie was placed by us, or by another
          advertiser who works with our partners. The information that we
          collect and share is anonymous and not personally identifiable. It
          does not contain your name, postal address(es), telephone number, or
          email address.
        </p>
        <p className="mt-3">
          <strong>
            3. Controlling the Collection, Use and Onward Transfer of Your
            Information.{" "}
          </strong>{" "}
          <br></br>The Personal Information we gather through our website and
          services serves the purpose of administering and delivering our
          services efficiently. To facilitate this, we may collaborate with
          third-party partners or vendors who assist in delivering or overseeing
          these services. However, these third parties are exclusively
          authorized to use this information in the context of delivering or
          managing our services. We may also share Personal Information with
          subsidiaries, affiliates, licensees, and subcontracted service
          providers associated with Signature Chauffeurs. Such sharing is
          executed only when the recipient requires this information to fulfill
          the services you have requested. The Personal Information collected
          via our website and services may also be employed to provide you with
          information or newsletters related to our services. Furthermore, we
          may promote our services to the company listed in your profile,
          provided that you have granted consent, if stipulated by applicable
          law. Non-personal information is used in an anonymized, aggregated
          manner for various purposes, encompassing the improvement of our
          website, services, and service delivery, internal marketing research,
          and management reporting. We uphold the prerogative to use and
          disclose this aggregated information to third parties for any
          legitimate purpose. This aggregated data does not allow for the
          identification of any specific individual. Additionally, we employ
          pixels or transparent GIF files to facilitate online advertising
          management. These GIF files are supplied by our advertising management
          partners. They enable our partners to recognize a unique cookie within
          your web browser, thereby aiding us in ascertaining which
          advertisements lead users to our website. It is important to note that
          the cookie is placed either by us or by an advertiser collaborating
          with our partners. The information collected and shared is anonymous
          and devoid of personally identifiable data, including your name,
          postal address(es), telephone number, or email address.
        </p>
        <p className="mt-3">
          <strong>4. Accessing your personal information</strong> <br></br>
          Our foremost objective is the efficient administration and seamless
          delivery of our services. To achieve this, we may collaborate with
          third-party partners or vendors whose involvement is expressly
          confined to the facilitation of service delivery or management.
          Importantly, these partners can only use this information within the
          precise scope of service provision. Additionally, we may share
          Personal Information with Signature Chauffeurs' subsidiaries,
          affiliates, licensees, and subcontracted service providers. This
          sharing is contingent upon these entities requiring access to such
          information to fulfill the specific services you have sought.
          Furthermore, the Personal Information derived from our website and
          services may also be employed to disseminate pertinent information or
          newsletters regarding our services. We may also promote our services
          to the company listed in your profile, provided you have given consent
          as stipulated by relevant laws. Non-personal information is utilized
          in an aggregated, anonymized manner for diverse purposes. These
          encompass enhancing the functionality of our website, optimizing
          service delivery, conducting internal marketing research, and
          generating comprehensive management reports. We assert the prerogative
          to use and disclose this aggregated data to third parties for various
          legitimate objectives. It is pivotal to note that such aggregated data
          cannot be utilized to discern the identity of individual users.
          Furthermore, we leverage pixels and transparent GIF files in the
          management of online advertising. These GIF files are provided by our
          advertising management partners and enable the recognition of a unique
          cookie within your web browser. This aids us in assessing the
          effectiveness of advertisements in guiding users to our website. It is
          essential to underscore that these cookies are placed either by us or
          by advertisers cooperating with our partners. The information gathered
          and exchanged remains anonymous and devoid of personally identifiable
          details, such as your name, postal address, telephone number, or email
          address.
        </p>
      </div>
      <div className="spacer-single"></div>
      <Footer />
    </div>
  );
}

export default Terms;
