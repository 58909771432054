import React, { useState } from "react";
import ServicesCard from "../service/ServicesCard";

function Goal() {
  const [Goal] = useState([
    {
      image: "assets/images/news/pic-blog-5.jpg",
      title: "Our Vision",
      description:
        "At Reliable Chauffeur Service, our vision is powered by cutting-edge software that empowers us to monitor vehicles in real-time, enhancing operational efficiency and minimizing downtime. ",
      route: "/about",
    },
    {
      image: "assets/images/news/mission.jpg",
      title: "Our Mission",
      description:
        "At Reliable Chauffeur Service, our mission is firmly rooted in delivering an exclusive, discreet, secure, and dependable luxury transportation service to be the industry's pinnacle.",
      route: "/about",
    },
    {
      image: "assets/images/news/app.jpg",
      title: "Our Approach",
      description:
        "At Reliable Chauffeur Service, our vision is centered on delivering the utmost in VIP service quality. Our maintaining and servicing our fleet to the most exacting standards our valued customers.",
      route: "/about",
    },
  ]);

  return (
    <div className="divv" id="section-news">
      <div className="container">
        <h1 className="blue choose-sig text-center mb-5 ">Our Goal</h1>
        <div className="row">
          {Goal.map((Goal, index) => (
            <ServicesCard data={Goal} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Goal;
