import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";

function ServiceTerms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/serviceterms.jpg"}
          title={"Service Terms and Conditions"}
          description={""}
        />
      </div>
      <div className="container">
        <h2 className="mt-4">Change, Cancellation and No-Show Policy</h2>
        <p className="mt-3">
          Change and Cancellation For any modifications or late cancellations to
          reservations in Australia, a fee will be applied. The fee for sedans,
          SUVs, limousines, and vans is $50.00 if you opt to change or cancel
          your reservation within the specified minimum time before your
          scheduled pick-up (24 hours prior). If the total charges for the
          reservation would have been less than $50.00, a lesser amount will be
          applied. For hourly reservations of sedans, SUVs, limousines, and
          vans, the change or late cancellation fee will equal the base rate
          multiplied by the hourly minimum. 2. No-Show Policy In the event of a
          passenger failing to cancel or meet the chauffeur at the designated
          pick-up location, a no-show fee will be imposed. This fee is
          equivalent to the hourly minimum or the base-to-base rate for the
          confirmed vehicle, plus airport fees, fuel surcharges, tolls, and
          parking fees as applicable. To avoid change, late cancellation, or
          no-show fees, reservations must be adjusted or canceled in compliance
          with the cancellation policy outlined in your email confirmation. You
          can do so by calling 1300 274 258 or updating your reservation at
          https://www.chauffeuraustralia.com. If you cannot locate your driver
          at the appointed service time, please contact the service provider.
          Leaving the pick-up location without notifying Reliable Chauffeur Service
          will result in a no-show fee, especially for reservations within the
          local city metropolitan area, defined as within 75 miles of the city
          center. All services outside this area may be assessed based on actual
          travel time to and from the pick-up location. Garage-to-Garage
          Calculations Charges for garage-to-garage services are calculated by
          applying the relevant hourly rate from when the chauffeur departs the
          garage to begin service until they return. The estimate includes a
          minimum billable hour, but the actual billable hours may exceed this
          minimum. Wait Time - Airport Transfer No wait time charges are applied
          for commercial flights unless the customer chooses to deviate from the
          agreed chauffeur meeting location. In this case, customers are allowed
          twenty minutes of wait time after the scheduled pick-up time. After
          twenty minutes, charges will be incurred, equal to the transfer base
          rate plus the hourly rate, calculated in fifteen-minute intervals.
          Wait Time - Point-to-Point Transfer For point-to-point transfer
          reservations, customers are granted twenty (20) minutes of wait time
          after the scheduled pickup time. Beyond the initial twenty minutes,
          charges will apply, equivalent to the transfer base rate plus the
          hourly rate, prorated in fifteen (15) minute intervals. Extra Stops
          Extra stops for transfer reservations may incur additional charges
          unless included in the initial quote. If a stop exceeds fifteen (15)
          minutes, the customer will be charged the transfer base rate plus the
          vehicle's hourly rate in fifteen (15) minute increments, up to
          forty-five (45) minutes (rounded up). If there is more than one stop
          or a single stop lasts more than thirty (30) minutes, the trip will
          convert to an hourly rate. Luggage/Baggage Information Sedans have a
          maximum luggage limit of two (2) normal-sized suitcases (checked
          baggage for a plane) plus two (2) pieces of hand luggage/soft luggage
          (carry-on for a plane), or one (1) large suitcase plus one (1) set of
          golf clubs and one (1) soft luggage. Additional luggage may require a
          larger vehicle, and additional charges apply. Surface Transportation
          Charge (STC) STC is a surcharge based on various overhead expenses,
          calculated as a flat percentage of the base rate. Incidentals
          Incidentals may include expenses such as chauffeur hotel
          accommodations and meals, greeter fees, parking, port fees, special
          requests, and tolls. Estimated Inclusive Quote Prices quoted before
          service are estimates and may change based on wait time and additional
          services. Exchange Rate Quoted rates are based on the exchange rate at
          the time of booking. The final bill will use the exchange rate at the
          time of billing. Airport Transfer, Hourly Charge, Point-to-Point
          Transfer, Base Rate Definitions for various charges and rates. Child
          Safety Seats Safety regulations dictate the use of age and
          weight-appropriate equipment for all children under 8 years old.
          Additional fees apply for baby capsules, baby seats, and booster
          seats. Safety Belts Passengers must wear seat belts while the vehicle
          is in motion. Chauffeur Conduct We uphold a zero-tolerance policy
          regarding drug and alcohol use to ensure the safety and well-being of
          our employees and customers. Report any suspicions of drug or alcohol
          use by an employee or chauffeur immediately by calling 1300 274 258.
          Pet and Service Animals Animals must be transported in a kennel or
          soft-sided pet container. Damage caused by animals may result in
          additional charges. For passengers with disabilities traveling with a
          service animal, the animal does not require a kennel or container.
          Accessibility Passengers with disabilities are permitted to be
          accompanied by a service animal without additional charge. Lost or
          Misplaced Items We are not liable for lost or misplaced personal
          property. Non-discrimination Policy Reliable Chauffeur Service provides
          transportation based solely on vehicle and chauffeur availability,
          without discrimination based on various factors, including race,
          gender, nationality, sexual orientation, religion, disability, age, or
          geographic location.
        </p>
      </div>
      <div className="spacer-single"></div>
      <Footer />
    </div>
  );
}

export default ServiceTerms;
