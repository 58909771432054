import React, { useEffect } from "react";
import Header from "../../Components/Home/Header";
import Breadcrumb from "../../Components/common/Breadcrumb";
import Footer from "../Footer";

function SmsTerms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/term.jpg"}
          title={"SMS Terms And Conditions"}
          description={""}
        />
      </div>
      <div className="container">
        <h2 className="mt-4">Introduction</h2>
        <p className="mt-3">
          <strong>Explanation of Service</strong> <br></br>Receive
          notifications from Reliable Chauffeur Service Alerts via SMS messages to stay
          informed about your reservations with Reliable Chauffeur Service. These
          alerts cover pre-reservation reminders and updates on your chauffeur's
          en route status and their arrival at your requested pick-up location.
        </p>
        <p className="mt-3">
          <strong>Acceptance of terms</strong> <br></br>By using the services
          provided by Reliable Chauffeur Service, you are agreeing to adhere to the
          terms and conditions outlined here, as well as those specific to
          Reliable Chauffeur Service's transportation services. You can opt to use
          Reliable Chauffeur Service's services without subscribing to Chauffeur
          Australia Alerts.
        </p>
        <p className="mt-3">
          <strong>Privacy policy</strong> <br></br>We respect the privacy of
          the personal information you provide to us and take measures to
          safeguard it.
        </p>
        <p className="mt-3">
          <strong>Subscribing to the Service</strong> <br></br>Any passenger
          profile with a valid mobile phone number linked to Reliable Chauffeur Service
          will be automatically enrolled upon setup unless otherwise instructed.
          SMS notifications sent by Reliable Chauffeur Service comply with relevant
          regulations, including the US Telephone Consumer Protection Act of
          1991 and the CAN-SPAM Act of 2003. To subscribe, visit your passenger
          profile's account settings page and provide your mobile number. Note
          that only one mobile number can be enrolled per passenger profile.
          Afterward, you can choose the text message alerts you wish to receive
          as part of the service.
        </p>
        <p className="mt-3">
          <strong>Opt-In</strong> <br></br>Using any of Reliable Chauffeur Service's
          services authorizes us to send Reliable Chauffeur Service Alerts via SMS to
          your mobile device unless you opt-out.
        </p>
        <p className="mt-3">
          <strong>STOP – How to End Your Subscription</strong> <br></br>To
          stop receiving Reliable Chauffeur Service alerts, reply to any SMS with the
          word STOP.
        </p>
        <p className="mt-3">
          <strong>G. STOP – How to Opt-Out or End Subscription</strong>{" "}
          <br></br>You can also opt-out by changing your notification
          preferences in your passenger profile's account settings at chauffeur
          australia You'll receive a confirmation, and Reliable Chauffeur Service alert
          messages will cease.
        </p>
        <p className="mt-3">
          <strong>H. HELP – How to Get Support</strong> <br></br>For assistance,
          call our toll-free number at 1300 274 258. You can request contact
          information anytime by texting HELP to the number that initially sent
          the SMS message.
        </p>
        <p className="mt-3">
          <strong>I. Pricing</strong> <br></br>
          Reliable Chauffeur Service Alerts are complimentary, dependent on your text
          plan, which may incur charges from your carrier. Message and data
          rates may apply. By using the Service, you confirm that you are at
          least 18 years old and either: (a) you are the mobile account holder,
          or (b) you have the mobile account holder's permission to do so.
        </p>
      </div>
      <div className="spacer-single"></div>
      <Footer />
    </div>
  );
}

export default SmsTerms;
