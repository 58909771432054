import React from 'react'
import ServicesCarousel from '../service/ServicesCarousel'
function Slider() {
  return (
    <div>
    <ServicesCarousel />  
    </div>
  )
}

export default Slider
