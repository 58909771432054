import React from "react";

function Faqs() {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <span style={{ color: "#031b4e" }} className="subtitle">
              Do You Have
            </span>
            <h2 style={{ color: "#28f1f4" }}>Any Questions?</h2>
            <div className="spacer-20"></div>
          </div>
        </div>
        <div className="row g-custom-x">
          <div className="col-md-12">
            <div
              className="accordion accordion-flush row"
              id="accordionFlushExample"
            >
              <div className="col-md-6">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      How Safe Are Our Chauffeured Cars?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      All of our cars are no more than a few years old – they
                      have been tested to meet the latest industry safety
                      standard requirements, and are tested regularly. Please be
                      assured that you will be traveling in some of the safest
                      cars on the road today. Our cars are top-of-the-range,
                      employing state of the art technology, so you can be sure
                      you will be looked after in safety and comfort.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Will my chauffeur respect my privacy and confidentiality?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Rest assured, we take confidentiality seriously. Before
                      commencing employment with Signature Chauffeured Cars, all
                      our drivers sign confidentiality agreements. This means
                      that any conversations or information shared within the
                      vehicle remains completely confidential.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      How do I meet my Chauffeur at Melbourne Airport?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      For international arrivals, once you've cleared customs,
                      please proceed to the right. You'll find our chauffeur
                      meeting point conveniently situated next to the Optus
                      store. If you're arriving from a domestic flight, head to
                      the bottom of the escalators in the arrivals hall. Rest
                      assured, your chauffeur will be readily available, holding
                      an iPad with your name clearly displayed.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      If my flight arrives early, will my chauffeur be there?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes. All our chauffeurs are monitoring all flights. Your
                      chauffeur will be there to meet your flight at the
                      scheduled time of landing.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      Can I set up an account with Reliable Chauffeur Service Cars?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Absolutely. Especially if you require a large volume of
                      bookings, we recommend setting up a business account with
                      us to streamline this process. You will then be invoiced
                      for all trips on a monthly basis. Feel free to give our
                      office a call for more information.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      What attire can I expect my chauffeur to wear?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      All our chauffeurs must wear our company uniform – A black
                      suit, and company tie, along with a unique chauffeur's
                      name tag.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
