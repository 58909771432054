import React from "react";

function WhyChoose() {
  return (
    <div className="divvvv">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6">
            <h1 className="blue choose-sig">
              Why Choose <br></br> Reliable Chauffeur Service?
            </h1>
            <br />
            <div className="dec-luxury text-black">
              At Reliable Chauffeur Service, we stand as the pinnacle of luxury
              chauffeur services in Melbourne. Our reputation is built on the
              bedrock of exceptional service and unwavering client satisfaction.
              Here's why you should make us your preferred choice for luxury
              transportation:
            </div>
            <br />
            <div
              id="sppb-addon-wrapper-1661142421832"
              className="sppb-addon-wrapper"
            >
              <div id="sppb-addon-1661142421832" className="clearfix ">
                <div className="sppb-addon sppb-addon-raw-html ">
                  <div className="sppb-addon-content">
                    <div className="block-contents text-black">
                      <ul>
                        <li>
                          <strong className="choose-head">
                            Professionalism:
                          </strong>&nbsp;
                          Our chauffeurs are not just skilled drivers; they are
                          the face of our company. Trained to perfection, they
                          exemplify professionalism and deliver high-standard
                          service.
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <strong className="choose-head">Punctuality:</strong>&nbsp;
                          Time is of the essence, and our chauffeurs understand
                          the significance of being on time. Count on us to be
                          ready and waiting for you, every time.
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <strong className="choose-head">Safety:</strong>&nbsp;
                          <span className="ul-sig">
                            Your safety is our paramount concern. Our vehicles
                            are equipped with top-of-the-line safety features to
                            ensure a secure journey for you and your
                            companions..
                          </span>
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <strong className="choose-head">
                            Comfort &amp; Style:
                          </strong>&nbsp;
                          Our vehicles are meticulously designed and maintained
                          to offer the ultimate in comfort and style. Whether
                          you're traveling for business or leisure, expect a
                          luxurious and comfortable ride.
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <span className="ul-sig">
                            <strong className="choose-head">
                              Local Knowledge:
                            </strong>
                            &nbsp;
                          </span>
                          <span className="ul-sig">
                            Our expert chauffeurs possess an intimate knowledge
                            of Melbourne and its surroundings. They enhance your
                            journey with valuable insights and recommendations,
                            making your ride more enjoyable.
                          </span>
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <strong className="choose-head">
                            Customized Services:
                          </strong>&nbsp;
                          No matter your needs in Melbourne, we have you
                          covered. From corporate travel to airport transfers,
                          sightseeing tours to wedding transportation, we tailor
                          our services to your unique requirements.
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <strong className="choose-head">
                            Accessibility:
                          </strong>&nbsp;
                          We offer seamless booking options, including online
                          reservations, and our customer support team is ready
                          to assist with any special requests.
                        </li>
                        <div className="space-haif"></div>
                        <li>
                          <strong className="choose-head">24/7 Service:</strong>
                          &nbsp;
                          <span className="ul-sig">
                            We are at your service around the clock. Whether
                            you're a business in need of transportation
                            solutions or an individual seeking a ride at any
                            hour, our chauffeurs are prepared to serve you, day
                            or night.
                            <br></br>
                            <div className="space-haif"></div>
                            Choose Reliable Chauffeur Service for a luxury
                            transportation experience that embodies
                            professionalism, punctuality, safety, comfort, local
                            expertise, customization, accessibility, and
                            round-the-clock availability.
                          </span>
                        </li>
                        <div className="space-haif"></div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 mt-4">
              <div className="post-text">
                <Link
                  to="/service"
                  style={{ backgroundColor: "#28f1f4" }}
                  type="button"
                  className="btn btn-lg text-white fw-bold"
                >
                  Our Services
                </Link>
              </div>
            </div> */}
          </div>
          <div className="col-md-6">
            <h2 style={{ color: "#28f1f4" }} className="sppb-addon-title">
              Luxury Best Chauffeur Services in Melbourne
            </h2>
            <div className="">
              <img
                className="sppb-img-responsive sppb-element-lazy sppb-element-loaded"
                src="/assets/images/why-main.jpg"
                data-large="/images/2023/09/04/weddingtop.jpg"
                alt="Luxury Best Chauffeur Services in Melbourne"
                title="Luxury Best Chauffeur Services in Melbourne"
                loading="lazy"
                width="633"
                height="805"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
