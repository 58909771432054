import React, { useState } from "react";
import ServicesCard from "../service/ServicesCard";

function Cards(props) {
  // eslint-disable-next-line
  const [Cards, setCards] = useState([
    {
      title: "Airport Transfer",
      description:
        "Experience the luxury of Reliable Chauffeur Service. so you can skip the taxi rank wait on your next journey.",
      route: "/services/airport-transfers",
    },
    {
      title: "Corporate Chauffeur",
      description:
        "Reliable Chauffeur Service values your time and offers efficient, premium transportation and making the most of your journey.",
      route: "/services/corporate-chauffeur",
    },
    {
      title: "Wedding Transfer",
      description:
        "Elevate your event experience with Reliable Chauffeur Service. Enjoy a luxurious, with Signature Chauffeured Cars.",
      route: "/services/wedding-transfer",
    },
  ]);

  return (
    <div className="divv" >
      <div className="container">
        <div className="row">
          {Cards.map((Cards, index) => (
            <ServicesCard data={Cards} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
export default Cards;
