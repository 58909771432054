import React from "react";

function Paragraph() {
  return (
    <div className="container ">
      <p className="mt-5">
        Kindly fill out the form below to make an inquiry about our booking
        services. You can expect a prompt response. For urgent bookings, feel
        free to reach out to us 24 hours a day, 7 days a week. Please bear in
        mind that this is an inquiry form and does not serve as a guaranteed
        booking. Our dedicated reservation team will promptly provide you with a
        quotation. For IMMEDIATE bookings, please do not hesitate to contact us
        anytime.
      </p>
    </div>
  );
}

export default Paragraph;
