import React from "react";

function CompanyRoadmap() {
  return (
    <div className="divv">
      <div className="container ">
        <div className="col text-center">
          <h2 className="blue">Company Roadmap</h2>
          <div className="spacer-20"></div>
        </div>
        <div className="main-timeline ">
          <div className="timeline">
            <div className="icon"></div>
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="month">2 Years</span>
                  <span className="year"><i className="fa fa-taxi" aria-hidden="true"></i></span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <p className="description text-black">
                Reliable Chauffeur Service proudly introduces an advanced operating
                system that streamlines all aspects of our operations, from
                bookings and dispatches to reporting and accounts management.
                This enhancement includes the establishment of a dedicated
                reservation and dispatch team, geared to meet the needs of our
                expanding clientele. As a direct result of this surge in
                business, we are delighted to welcome a growing number of
                vehicles into our ever-expanding fleet.
              </p>
            </div>
          </div>
          <div className="timeline">
            <div className="icon"></div>
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="month">1 Years</span>
                  <span className="year"><i className="fa fa-taxi" aria-hidden="true"></i></span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <p className="description text-black text-start">
                Like many businesses, Reliable Chauffeur Service has also confronted
                the challenges brought on by the COVID-19 pandemic. In these
                trying times, it's easy to lose sight of our aspirations.
                Signature Chauffeur Cars, too, had to put their dreams of
                expansion on hold due to the impact of the Coronavirus. As a
                united team, we've made the decision to shift our focus toward
                our families and communities, recognizing the importance of
                supporting one another during this difficult period.
              </p>
            </div>
          </div>
          <div className="timeline">
            <div className="icon"></div>
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="month">2 Years</span>
                  <span className="year"><i className="fa fa-taxi" aria-hidden="true"></i></span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <p className="description text-black">
                In the wake of the pandemic, our company experienced remarkable
                growth and now boasts a fleet of over 15 vehicles. We are
                delighted to report that our customers are highly satisfied with
                the service we offer, and we're thrilled to announce that we are
                back on a path of success. Additionally, our recent launch of a
                new website has had a profoundly positive impact on our
                business.
              </p>
            </div>
          </div>
          <div className="timeline">
            <div className="icon"></div>
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="month">2 Years</span>
                  <span className="year"><i className="fa fa-taxi" aria-hidden="true"></i></span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <p className="description text-black text-start">
                Reliable Chauffeur Service had its inception with the acquisition of
                its inaugural vehicle. In the early days, our business was a
                one-person operation, where Hassan took on the roles of
                chauffeur, reservations officer, and dispatch officer all at
                once. It was a humble beginning that laid the foundation for our
                journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyRoadmap;
