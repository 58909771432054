import React from 'react'

function Card() {
  return (
    <div id="section-news" className="no-top mt-50 divvvvvv" >
    <div className="container">
        <div className="row">
            <div className="col-lg-4 mb20">
                <div className="bloglist s2 item">
                    <div className="post-content">
                        <div className="post-image">
                            <img alt="" src="assets/images/news/pic-blog-1.jpg" className="lazy"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 mb20">
                <div className="bloglist s2 item">
                    <div className="post-content">
                        <div className="post-image">
                            <img alt="" src="assets/images/news/pic-blog-2.jpg" className="lazy"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 mb20">
                <div className="bloglist s2 item">
                    <div className="post-content">
                        <div className="post-image">
                            <img alt="" src="assets/images/news/pic-blog-3.jpg" className="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Card
