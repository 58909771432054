import React, { useEffect } from "react";
import Header from "../Components/Home/Header";
import Breadcrumb from "../Components/common/Breadcrumb";
import Paragraph from "../Components/quote/Paragraph";
import RequestQuote from "../Components/quote/RequestQuote";
import Footer from "./Footer";

function QuoteNow() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" className="bg-white text-black">
      <Header />
      <div className="no-bottom pb-0 no-top bg-white text-black" id="content">
        <div id="top"></div>
        <Breadcrumb
          image={"/assets/images/background/ser.jpg"}
          title={"Quote Now"}
          description={
            "Plan your journey with ease. Receive an instant quote for our chauffeur services to ensure a seamless and convenient experience."
          }
        />
      </div>
      <Paragraph />
      <RequestQuote />
      <Footer />
    </div>
  );
}

export default QuoteNow;
